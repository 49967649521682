<template>
  <div class="products category-med white">
    <Breadcrumb
      :items="category.navigationTypeId != 0 ? breadcrumbs : breadcrumb"
    />
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <CategoryTitle
      :category="category"
      :selectedCategory="selectedCategory"
      :count="count"
    />
    <v-container fluid class="py-0">
      <v-chip-group
        show-arrows
        v-if="category.children"
        class="py-3 category-chip"
      >
        <v-chip
          v-for="subCategory in category.children"
          :key="subCategory.categoryId"
          link
          exact-active-class=""
          :to="
            category.navigationTypeId == 0
              ? { name: 'Category', params: { pathMatch: subCategory.slug } }
              : {
                  name: 'Category',
                  params: {
                    pathMatch: category.slug
                  },
                  query: { categoryId: subCategory.categoryId }
                }
          "
          class="pipoooo mr-2 mb-2"
        >
          {{ subCategory.name }}
        </v-chip>
      </v-chip-group>
      <v-divider />
    </v-container>
    <v-container fluid>
      <ProductListGrid
        :parentCategoryId="category.categoryId"
        :categoryId="categoryId"
        :key="categoryId || category.categoryId"
        hideFilterString="Categorie,Main Term"
        @productsCount="updateCount"
        :pageSize="$ebsn.meta(category, 'template_model.PAGE_SIZE')"
        :virtualScrollViewport="$vuetify.breakpoint.xsOnly"
        :virtualPageSize="$vuetify.breakpoint.xsOnly ? 4 : 1"
      />
      <!-- :pageSize="$ebsn.meta(category, 'template_model.PAGE_SIZE')" -->
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_TEXT"
        tag="div"
      />
    </v-container>
  </div>
</template>

<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMixins from "~/mixins/category";
import toNumber from "lodash/toNumber";
import get from "lodash/get";

export default {
  name: "CategoryMed",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    CategoryTitle,
    Breadcrumb
    // ,
    // CategoryBlock
  },
  data() {
    return { count: -1, categoryId: null };
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Home",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    selectedCategory() {
      if (this.categoryId) {
        return this.category.children.find(
          item => item.categoryId == this.categoryId
        );
      } else {
        return null;
      }
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  created() {
    this.categoryId = toNumber(this.$route.query.categoryId);
  },
  watch: {
    async "$route.query.categoryId"() {
      this.resetFilters();
      this.categoryId = this.$route.query.categoryId;
    }
  },
  metaInfo() {
    const title = get(
      this.category,
      "metaData.category_seo.SEO_TITLE",
      this.category ? this.category.name : ""
    );
    const description = get(
      this.category,
      "metaData.category_seo.SEO_DESCRIPTION",
      "Categoria: " + this.category ? this.category.name : ""
    );
    return {
      title: title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "product.group"
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "cooptrentino.it"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: title
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
<style scoped lang="scss">
.products.category-med .category-title {
  padding: 0 32px !important;
}
.v-chip.v-size--default {
  border-radius: 6px !important;
  background-color: #f5f2e2 !important;
}
.v-chip {
  font-weight: normal;
}
.v-chip--active {
  font-weight: bold !important;
}
.category-chip {
  .v-chip {
    font: normal normal 600 15px/37px $body-font-family;
    height: 35px;
  }
}
</style>
